import React from "react"
import { useTranslation, Trans } from "react-i18next"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBell, faCheckSquare, faCheck, faChartBar, faCircle, faDollarSign, faMapMarker, faStar, faQuoteLeft, faQuoteRight, faFileAlt, faLock, faMobileAlt } from "@fortawesome/free-solid-svg-icons"

import SEO from '../components/Seo/Seo';
import { getSignUpLink } from "../i18n";
import '../styles/pages/index.scss';

const IndexPage = ({ data, location }) => {
    const { i18n, t } = useTranslation();

    return (
        <>
            <SEO
                title={t("meta.home.title")}
                lang={i18n.language}
                description={t("meta.home.description")}
                pathname={location.pathname}
            />

            <section id="home">
                <div className="container text-center">
                    <h1 className="text-white">
                        <Trans i18nKey="homePage.section1.title">
                            Plánujte pracovné zmeny pre zamestnancov <br/><span className="text-success">jednoducho</span> a <span className="text-success">online</span>
                        </Trans>
                    </h1>
                    <p className="text-white py-4" style={{fontSize: 1.25 + 'rem', fontWeight: 300}}>
                        {t('homePage.section1.subtitle')}
                    </p>
                    <div className="header-buttons">
                      <a href={getSignUpLink(i18n.language)}
                        className="btn btn-success btn-lg"
                      >
                        {t('label.signUpButton')}
                      </a>
                      {/*<a href="#" class="btn btn-outline"><i class="fa fa-play-circle-o" aria-hidden="true"></i>&nbsp;&nbsp;Pozrieť video</a>*/}
                    </div>

                    <Img
                      fluid={data.schedulinoGrid.childImageSharp.fluid}
                      alt={t("homePage.section1.img")}
                      className="mx-md-5"
                    />
                </div>
            </section>

            <section className="content bg-light py-4">
                <div className="container pb-5">
                    <h2 className="content-title dark">{t('homePage.section2.title')}</h2>
                    <div className="row">
                        <div className="col text-center">
                            <p>{t('homePage.section2.content1')}</p>
                            <p>{t('homePage.section2.content2')}</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="position-relative">
                <div className="custom-shape-divider-bottom">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path
                            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                            fill="#fff"></path>
                    </svg>
                </div>
            </section>
            <section className="content bg-white pt-4 pb-1">
                <div className="container pb-5">
                    <h2 className="content-title dark text-center mt-5">{t('homePage.section3.title')}</h2>

                    <div className="row py-md-4">
                        <div className="col-md-6 align-self-center">
                            <h3>1. {t('homePage.section3.subsection1.title')}</h3>
                            <p>{t('homePage.section3.subsection1.content')}</p>
                        </div>
                        <div className="col-md-6 align-self-center">
                            <Img fluid={data.availabilityGrid.childImageSharp.fluid} className="features-img-wrapper" alt={t('homePage.section3.subsection1.img')} />
                        </div>
                    </div>

                    <div className="d-none d-sm-block">
                        <div className="line">
                            <svg width="472px" height="162px" viewBox="0 0 472 162" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-dasharray="2,6">
                                    <g transform="translate(-469.000000, -2578.000000)" stroke="#949494" stroke-width="1.5">
                                        <g transform="translate(270.000000, 1694.000000)">
                                            <g>
                                                <g transform="translate(0.000000, 199.000000)">
                                                    <g transform="translate(435.000000, 766.000000) scale(-1, 1) translate(-435.000000, -766.000000) translate(200.000000, 685.000000)">
                                                        <path d="M0,161.98 C-5.43085932e-15,117.633668 35.8756312,81.6447157 80.2217424,81.504859 L389.724805,80.5287624 C434.100464,80.3888125 470,44.3758801 470,0 L470,0 L470,0"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-md-6 order-md-2 align-self-center">
                            <h3>2. {t('homePage.section3.subsection2.title')}</h3>
                            <p>{t('homePage.section3.subsection2.content')}</p>
                        </div>
                        <div className="col-md-6 order-md-1 align-self-center">
                            <Img fluid={data.addShift2.childImageSharp.fluid} className="features-img-wrapper" alt={t('homePage.section3.subsection2.img')} />
                        </div>
                    </div>

                    <div className="d-none d-sm-block">
                        <div className="line">
                            <svg width="472px" height="162px" viewBox="0 0 472 162" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-dasharray="2,6">
                                    <g transform="translate(-469.000000, -2168.000000)" stroke="#949494" stroke-width="1.5">
                                        <g transform="translate(270.000000, 1694.000000)">
                                            <g>
                                                <g transform="translate(0.000000, 199.000000)">
                                                    <g transform="translate(200.000000, 275.000000)">
                                                        <path d="M0,161.98 C-5.43085932e-15,117.633668 35.8756312,81.6447157 80.2217424,81.504859 L389.724805,80.5287624 C434.100464,80.3888125 470,44.3758801 470,0 L470,0 L470,0"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>

                    <div className="row my-5">
                        <div className="col-md-6 align-self-center">
                            <h3>3. {t('homePage.section3.subsection3.title')}</h3>
                            <p>{t('homePage.section3.subsection3.content')}</p>
                        </div>
                        <div className="col-md-6">
                            <Img fluid={data.scheduleGridMobile.childImageSharp.fluid} alt={t("homePage.section3.subsection3.img")} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="position-relative">
                <div className="custom-shape-divider-bottom">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path
                            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                            fill="#f1f3f4"></path>
                    </svg>
                </div>
            </section>
            <section className="content bg-light py-4 position-relative">
                <div className="container pb-5">
                    <h2 className="content-title dark text-center mt-5">{t('homePage.section4.title')}</h2>
                    <p className="text-center">{t('homePage.section4.subtitle')}</p>

                    <div className="row py-md-4">
                        <div className="col-md-6 align-self-center">
                            <h3>{t('homePage.section4.subsection1.title')}</h3>
                            <p>{t('homePage.section4.subsection1.content')}</p>
                        </div>
                        <div className="col-md-6 align-self-center">
                            <Img fluid={data.scheduleGrid2.childImageSharp.fluid} className="features-img-wrapper" alt={t("homePage.section4.subsection1.img")} />
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-md-6 order-md-2 align-self-center">
                            <h3>{t('homePage.section4.subsection2.title')}</h3>
                            <p>{t('homePage.section4.subsection2.content')}</p>
                        </div>
                        <div className="col-md-6 order-md-1 align-self-center">
                            <Img fluid={data.scheduleGrid.childImageSharp.fluid} className="features-img-wrapper" alt={t("homePage.section4.subsection2.img")} />
                        </div>
                    </div>

                    <div className="row mt-5 text-center">
                        <div className="container">
                            <h3>{t('homePage.section4.subsection3.title')}</h3>
                            <p>{t('homePage.section4.subsection3.content')}</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="position-relative">
                <div className="custom-shape-divider-bottom">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path
                            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                            fill="#fff"></path>
                    </svg>
                </div>
            </section>

            <section className="content bg-white pt-4 pb-1">
                <div className="container pb-5">
                    <h2 className="content-title dark text-center mt-5">{t('homePage.features.title')}</h2>
                    <ul className="row list-unstyled text-center">
                        <li className="col-md-4 mb-3">
                      <span className="fa-stack fa-2x mb-2">
                           <FontAwesomeIcon icon={faCircle} className="fa-stack-2x" />
                           <FontAwesomeIcon icon={faFileAlt} className="fa-stack-1x" inverse />
                      </span>
                            <h3 className="h4">{t("homePage.features.shiftTemplatesTitle")}</h3>
                            <p>{t("homePage.features.shiftTemplatesSubtitle")}</p>
                        </li>
                        <li className="col-md-4 mb-3">
                      <span className="fa-stack fa-2x mb-2">
                           <FontAwesomeIcon icon={faCircle} className="fa-stack-2x" />
                           <FontAwesomeIcon icon={faMapMarker} className="fa-stack-1x" inverse />
                      </span>
                            <h3 className="h4">{t("homePage.features.locationsTitle")}</h3>
                            <p>{t("homePage.features.locationsSubtitle")}</p>
                        </li>
                        <li className="col-md-4 mb-3">
                      <span className="fa-stack fa-2x mb-2">
                           <FontAwesomeIcon icon={faCircle} className="fa-stack-2x" />
                           <FontAwesomeIcon icon={faBell} className="fa-stack-1x" inverse />
                      </span>
                            <h3 className="h4">{t("homePage.features.notificationTitle")}</h3>
                            <p>{t("homePage.features.notificationSubtitle")}</p>
                        </li>
                        <li className="col-md-4 mb-3">
                      <span className="fa-stack fa-2x mb-2">
                           <FontAwesomeIcon icon={faCircle} className="fa-stack-2x" />
                           <FontAwesomeIcon icon={faCheckSquare} className="fa-stack-1x" inverse />
                      </span>
                            <h3 className="h4">{t("homePage.features.shiftConfirmationTitle")}</h3>
                            <p>{t("homePage.features.shiftConfirmationSubtitle")}</p>
                        </li>
                        <li className="col-md-4 mb-3">
                      <span className="fa-stack fa-2x mb-2">
                           <FontAwesomeIcon icon={faCircle} className="fa-stack-2x" />
                           <FontAwesomeIcon icon={faCheck} className="fa-stack-1x" inverse />
                      </span>
                            <h3 className="h4">{t("homePage.features.availabilityPreferencesTitle")}</h3>
                            <p>{t("homePage.features.availabilityPreferencesSubtitle")}</p>
                        </li>
                        <li className="col-md-4 mb-3">
                      <span className="fa-stack fa-2x mb-2">
                           <FontAwesomeIcon icon={faCircle} className="fa-stack-2x" />
                           <FontAwesomeIcon icon={faDollarSign} className="fa-stack-1x" inverse />
                      </span>
                            <h3 className="h4">{t("homePage.features.laborCostTitle")}</h3>
                            <p>{t("homePage.features.laborCostSubtitle")}</p>
                        </li>
                        <li className="col-md-4 mb-3">
                      <span className="fa-stack fa-2x mb-2">
                           <FontAwesomeIcon icon={faCircle} className="fa-stack-2x" />
                           <FontAwesomeIcon icon={faLock} className="fa-stack-1x" inverse />
                      </span>
                            <h3 className="h4">{t("homePage.features.roleBasedViewsTitle")}</h3>
                            <p>{t("homePage.features.roleBasedViewsSubtitle")}</p>
                        </li>
                        <li className="col-md-4 mb-3">
                      <span className="fa-stack fa-2x mb-2">
                           <FontAwesomeIcon icon={faCircle} className="fa-stack-2x" />
                           <FontAwesomeIcon icon={faChartBar} className="fa-stack-1x" inverse />
                      </span>
                            <h3 className="h4">{t("homePage.features.payrollReportTitle")}</h3>
                            <p>{t("homePage.features.payrollReportSubtitle")}</p>
                        </li>
                        <li className="col-md-4 mb-3">
                      <span className="fa-stack fa-2x mb-2">
                           <FontAwesomeIcon icon={faCircle} className="fa-stack-2x" />
                           <FontAwesomeIcon icon={faMobileAlt} className="fa-stack-1x" inverse />
                      </span>
                            <h3 className="h4">{t("homePage.features.mobileTitle")}</h3>
                            <p>{t("homePage.features.mobileSubtitle")}</p>
                        </li>
                    </ul>
                    {/*<div className="text-center">*/}
                    {/*    <a href={getSignUpLink(i18n.language)} className="btn btn-success btn-lg mt-3">{t("homePage.features.fullFeatureList")}</a>*/}
                    {/*</div>*/}
                </div>
            </section>

            <section id="testimonials" className="content">
                <div className="custom-shape-divider-top">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path
                            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                            fill="#fff"></path>
                    </svg>
                </div>
                <div className="container text-center text-white">
                    <h2 className="content-title text-white">{t("homePage.testimonialsTitle")}</h2>
                    <div className="row">
                        <blockquote className="blockquote mt-3 col-md-6 px-3">
                            <div className="mb-2" style={{color: "#f7c94a"}}>
                                {
                                    [...Array(5)].map((el, index) => <FontAwesomeIcon icon={faStar} fixedWidth key={index} />)
                                }
                            </div>
                            <FontAwesomeIcon icon={faQuoteLeft} fixedWidth />
                            <span className="font-weight-light font-italic">{t("homePage.testimonialsClient1")}</span>
                            <FontAwesomeIcon icon={faQuoteRight} fixedWidth />
                            <div className="blockquote-footer text-white mt-3">
                                Lucia Korenková, Kidsfun s.r.o.
                            </div>
                        </blockquote>
                        <blockquote className="blockquote mt-3 col-md-6 px-3">
                            <div className="mb-2" style={{color: "#f7c94a"}}>
                                {
                                    [...Array(5)].map((el, index) => <FontAwesomeIcon icon={faStar} fixedWidth key={index} />)
                                }
                            </div>
                            <FontAwesomeIcon icon={faQuoteLeft} fixedWidth />
                            <span className="font-weight-light font-italic">{t("homePage.testimonialsClient2")}</span>
                            <FontAwesomeIcon icon={faQuoteRight} fixedWidth />
                            <div className="blockquote-footer text-white mt-3">
                                Paul Wilson, Propaganda Freestyle Park
                            </div>
                        </blockquote>
                    </div>
                </div>
            </section>
        </>
    )
};

export default IndexPage

export const query = graphql`
  query indexPage($locale: String) {
    schedulinoGrid: file(relativeDirectory: { eq: $locale }, name: { eq: "schedulino_grid" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    schedulinoCalendar: file(relativeDirectory: { eq: $locale }, name: { eq: "schedulino_calendar" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    scheduleGrid: file(relativeDirectory: { eq: $locale }, name: { eq: "schedule_grid" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    scheduleGrid2: file(relativeDirectory: { eq: $locale }, name: { eq: "schedule_grid2" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    addShift2: file(relativeDirectory: { eq: $locale }, name: { eq: "add_shift2" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    scheduleGridMobile: file(relativeDirectory: { eq: $locale }, name: { eq: "schedule_mobile_iphone8silver_portrait" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    availabilityGrid: file(relativeDirectory: { eq: $locale }, name: { eq: "availability_grid" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    requests: file(
      relativeDirectory: { eq: $locale }
      name: { eq: "requests" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
